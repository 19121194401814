// IMPORTS
@import (reference) "../settings.less";

// MAIN BANNER

.main-banner-wrapper {
    width: 100%;
    aspect-ratio: ~"1440/580";
    position: relative;
}

.main-banner {
    height: 100%;
    aspect-ratio: ~"1920/580";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .swiper-wrapper {
        aspect-ratio: ~"1920/580";
    }

    .banner-img-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        img {
            .img-crop();
        }
    }

    .text-wrapper {
        position: absolute;
        bottom: 64px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 2px;
        max-width: 66%;
        color: white;
        background-color: rgba(35, 31, 32, 0.9);
        padding: 20px 87px;
        line-height: 1.2;

        b {
            font-size: 24px;
            font-weight: 200;
            text-transform: uppercase;
        }
    }
}

.swiper-pagination.banner-pagination {
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;

    .swiper-pagination-bullet {
        border-radius: 0;
        opacity: 1;
        margin: 0;
        width: 32px;
        height: 36px;
        position: relative;
        margin-right: 6px;
        background-color: transparent;
        transition: width 0.05s ease-in-out;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            background-color: @sec-color;
            height: 4px;
            transform: translateY(-50%);
        }

        &.swiper-pagination-bullet-active {
            width: 48px;

            &:after {
                background-color: white;
            }
        }
    }
}

// REC BANNERS
.rec-banners-section {

    .headline {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 23px;
    }
}

.rec-banners-wrapper {
    .grid();
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    position: relative;
}

.rec-banner {
    overflow: hidden;
    position: relative;
    aspect-ratio: ~"460/480";

    figure {
        width: 100%;
    }

    img {
        .img-crop();
    }

    .moving-content-container {
        position: absolute;
        inset: 0;
        color: white;

        &:after, //2 gradients
        &:before {
            content: '';
            position: absolute;
            inset: 0;
            height: 100%;
            z-index: 0;
            transition: all .3s ease-in-out;
        }

        &:after {
            background: linear-gradient(180.00deg, rgba(35, 31, 32, 0) 57.187%, rgba(35, 31, 32, 0.6) 100%);
            transition-delay: .0s;
        }

        &:before {
            background: linear-gradient(180.00deg, rgba(35, 31, 32, 0), rgb(35, 31, 32) 100%);
            opacity: 0;
            transition-delay: .03s;
        }

        div {
            display: flex;
            gap: 4px;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0 32px;
            color: white;
            position: absolute;
            top: 100%;
            transform: translateY(-50px);
            width: 100%;
            transition: all .3s ease-in-out;
            z-index: 2;
        }

        b {
            font-size: 24px;
            font-weight: 200;
            line-height: 1.2;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            & + span {
                opacity: 0;
                transition: opacity .3s ease-in-out;
                line-height: 1.2;
            }
        }

        i {
            opacity: 0;
            transition: opacity .3s ease-in-out;
            font-style: normal;
            color: white;
            height: 29px;
            margin-top: 20px;

            &:hover {
                border: 1px solid @main-color;
            }
        }
    }

    @media (min-width: 1280px) {
        &:hover {

            .moving-content-container {

                &:after {
                    opacity: 0;
                    transition-delay: .2s;
                }

                &:before {
                    opacity: 1;
                    transition-delay: .16s;
                }

                div {
                    top: 50%;
                    transform: translateY(-50%);
                    transition-delay: .2s;
                }

                span,
                i {
                    opacity: 1;
                    transition-delay: 0.5s;
                }
            }
        }
    }
}
